//
// Variables
// --------------------------------------------------

//== Typography

$heading-line-height:   1.3 !default;
$heading-font-weight:   normal !default;
$heading-color: 				#474747 !default;
$text-font-size:			  18px !default;
$text-font-size-mobile:	15px !default;
$font-default:					'Roboto', 'Open Sans', sans-serif;
$font-default-light:		'Roboto', 'Open Sans', sans-serif;
$font-heading-default:  'Roboto', 'Open Sans', sans-serif;

//== Colors

//** Main theme color
$brand-primary:         #FBA601 !default;
$brand-primary-lighter: lighten($brand-primary, 15%) !default;
$brand-primary-darker:  darken($brand-primary, 15%) !default;

//** Global text color on `<body>`.
$text-color:            #474747 !default;
//** Darken text color
$text-color-darken:     darken($text-color, 15%) !default;
//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      lighten($link-color, 15%) !default;

//== Components

$border-radius-none:        0 !default;
$border-radius-base:        5px !default;
$border-radius-oval:        50px !default;
$border-radius-circle:      100% !default;

//== Tables

//** Border color
$table-border-color:        #cecece !default;

//== Forms

//** Placeholder text color
$input-placeholder-color:        #636363 !default;
//** Placeholder text transform
$input-placeholder-text-transform: none !default;
//** Placeholder font family
$input-placeholder-font-family: $font-default !default;
//** Placeholder font weight
$input-placeholder-font-weight: normal !default;
//** Input padding
$input-padding:        			     10px !default;
//** Input background color
$input-bg-color:        			   #fff !default;
//** Input text color
$input-text-color:        			 $heading-color !default;
//** Input border color
$input-border-color:             $table-border-color !default;
//** Input border color on focus
$input-border-hover-color:       $heading-color !default;
//** Form text font size
$form-text-font-size:       		 16px !default;
//** Form text line-height
$form-text-line-height:       	 26px !default;
//** Form text font weight
$form-text-font-weight:       	 normal !default;
//** Form validation error border color
$form-validation-error-border-color: #b94a48 !default;
//** Form validation error color
$form-validation-error-color:    #b94a48 !default;
//** Form validation error bg color
$form-validation-error-bg-color: $input-bg-color !default;
//** Input label font size
$input-label-font-size:       	 14px !default;
//** Checkbox box size 
$checkbox-box-size:       	     24px !default;
//** Checkbox box border width 
$checkbox-box-border-width:      1px !default;
//** Checkbox box height 
$checkbox-box-height:       	   $checkbox-box-size + ($checkbox-box-border-width * 2) !default;
//** Checkbox icon font size
$checkbox-icon-font-size:        20px !default;
//** Radio checked circle size 
$radio-checked-circle-size:      14px !default;
//** Radio checked circle gap 
$radio-checked-circle-gap:       ($checkbox-box-height - $radio-checked-circle-size) / 2 !default;
//** Textarea line height
$textarea-line-height:       	   26px !default;

//== Header

// Mobile?
//** Main menu submenu active link bg color
$submenu-link-active-bg-color:   transparent !default;
//** Main menu submenu link bg color on hover
$submenu-link-bg-color-hover:    #fff !default;
//** Main header gap width between elements - menu, button etc.
$header-elements-gap:    				 20px !default;
//** Main menu active link color
$main-menu-link-active-color:    $brand-primary !default;

//== Page section | Checkout

//** Form padding
$checkout-form-padding:            30px !default;
//** Row padding
$checkout-row-padding:             20px !default;
//** Row heading font size
$checkout-row-heading-font-size:   16px !default;
//** Checkout info header bg color
$checkout-info-header-bg-color:    #67b930 !default;