//
// Divi | Fullwidth Google map and content above it
// --------------------------------------------------

$row-map-info-width:                300px;
$map-address-padding:               40px;

.pl-divi-section-map {
    .et_pb_row {
        padding: 0;
    }

    .et_pb_map_container {
        margin-bottom: 0 !important;
    }

    .pl-divi-row-map-info {
        position: absolute;
        top: 10%;
        left: calc( (100% - 1080px) / 2);
        width: $row-map-info-width;
        z-index: 1;

        @include responsive('tablet', 'max') {
            position: static;
            width: 100%;
            border-top: 1px solid $table-border-color;
        }

        @include responsive('tablet-wide', 'max') {
            left: 10%;
        }

        @media only all
        and (min-width: 768px)
        and (max-width: 1024px)
        and (orientation: landscape) {
            left: 10%;
        }

        &.info-right-side {
            left: auto;
            right: calc( (100% - 1080px) / 2);
    
            @include responsive('tablet-wide', 'max') {
                left: auto;
                right: 10%;
                top: 10%;
            }
    
            @media only all
            and (min-width: 768px)
            and (max-width: 1024px)
            and (orientation: landscape) {
                left: auto;
                right: 10%;
            }
        }

        .et_pb_column:not(.et_pb_column_empty) {
            background-color: #fff;
            box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.21);
            // border-radius: $border-radius-base;

            @include responsive('tablet-wide', 'max') {
                width: 80% !important;
                float: right;
            }

            @include responsive('tablet', 'max') {
                box-shadow: none;
                border-radius: 0;
                width: 100% !important;
            }

            
        }

        .et_pb_button_module_wrapper {
            padding: 30px 0;
            background-color: #f7f7f7;
            // border-radius: 0 0 $border-radius-base $border-radius-base;

            @include responsive('tablet', 'max') {
                padding: 30px 10%;

                .pl-btn {
                    width: 100%;
                }
            }
        }
    }

    .pl-divi-row-map-button-only {
        position: absolute;
        bottom: 54px;
        right: calc( (100% - 1080px) / 2);
        width: auto;
        z-index: 1;
        
        @include responsive('tablet-wide', 'max') {
            right: 54px;
        }

        @media only all
        and (min-width: 768px)
        and (max-width: 1024px)
        and (orientation: landscape) {
            right: 10%;
        }

        .pl-btn {
            width: 200px;

            @include responsive('tablet', 'max') {
                width: 100%;
            }
        }
    }

    .pl-divi-map-address {
        padding: $map-address-padding;

        @include responsive('tablet', 'max') {
            padding: $map-address-padding 10%;
        }

        @include responsive('tablet-wide', 'max') {
            padding: $map-address-padding / 2;
        }

        .et_pb_blurb_content {
            display: table;
            width: 100%;
            max-width: 100%;

            p {
                padding-bottom: 5px;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .et_pb_main_blurb_image,
        .et_pb_blurb_container {
            width: 50%;

            @include responsive('tablet', 'max') {
                width: 100%;
                display: block;
            }
        }

        .et_pb_main_blurb_image {
            text-align: left;

            img {
                width: 200px;
            }
        }

        .et_pb_blurb_container {
            padding-left: 30px;

            @include responsive('tablet', 'max') {
                padding-left: 0;
            }
        }
    }
}