//
// Divi module | Testimonial
// --------------------------------------------------

// Testimonial with all content positioned in center
.pl-divi-testimonial-centered.et_pb_testimonial {
  padding: 0;
  width: 66.66666%;
  margin: 0 auto;

  @include responsive('tablet', 'max') {
    width: 100%;
  }

  .et_pb_testimonial_portrait {
    display: block;
    float: none;
    margin: 0 auto 30px;
    background-size: 80%;
    background-color: #fff;
    border: 20px solid #fff;
    box-sizing: content-box;

    &:before {
      display: none;
    }
  }

  .et_pb_testimonial_description {
    p {
      padding-bottom: 0;
    }
  }
}