//
// Common styles
// --------------------------------------------------

// Base
//
// Basic styles.
//
// Styleguide Base

body {
  font-family: $font-default;
  color: $text-color;
  font-weight: normal !important;
  hyphens: auto;
}

img {
  display: inline-block;
  vertical-align: middle;
}

a {
  cursor: pointer;
  color: $link-color;
  @include transition;

  &:hover {
    color: $link-hover-color;
  }

  &.color-brand {
    color: $brand-primary;
  
    &:hover {
      color: $brand-primary-lighter;
    }
  }

  &.color-heading {
    color: $heading-color;

    &:hover {
      opacity: 0.9;
    }
  }

  &.color-text {
    color: $text-color;

    &:hover {
      color: $text-color-darken;
    }
  }
}

.pl-block {
  display: block;
}

.pl-inline-block {
  float: none !important;
  display: inline-block;
  vertical-align: middle;

  &.pl-vertical-top {
    vertical-align: top;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pl-hidden {
  visibility: hidden;
}

.color-brand {
  color: $brand-primary;
}

.pl-text-white {
  color: #fff;
}

.pl-separator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 20px;
  background-color: $table-border-color;
  margin: 0 10px;
}

.pl-link-arrow-right {
  @include inline-block();
  color: $heading-color;
  text-transform: uppercase;
  font-family: $font-heading-default;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2px;

  &:after {
    @include pseudo(inline-block, relative, '\24');
    @extend %pl-icon;
    vertical-align: middle;
    color: $brand-primary;
    font-size: 30px;
    margin: -1px 0 0 5px;
  }

  &:hover {
    color: $brand-primary;
  }
}

.pl-text-first-letter-styled {
  &::first-letter {
    font-size: 58px;
    color: $heading-color;
    float: left;
    line-height: 34px;
    margin: 18px 6px 0 0;
  }
}

.pl-text-big-red {
  color: $brand-primary;
  font-size: 76px;
  font-weight: normal;
  line-height: 0.9em;
  padding-bottom: 10px;
  margin-left: -6px;

  @include responsive('tablet-wide', 'max') {
    margin-left: 0;
  }
}

.pl-text-stripe-after {
  position: relative;
  padding-bottom: 25px !important;
  margin-bottom: 25px;

  &:after {
    @include pseudo();
    width: 50px;
    height: 2px;
    bottom: 0;
    background-color: $heading-color;
  }
}

.pl-subheading {
  color: $brand-primary;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: $font-heading-default;
  line-height: 1;
  padding-bottom: 15px;
}