//
// Site Spicific Styles
// --------------------------------------------------

#et-top-navigation {
	margin: -23px 0 10px 0;

	.et-fixed-header & {
		margin: -10px 0 10px 0;
	}

	@include responsive('tablet-wide', 'max') {
		margin: -14px 0 10px 0;

		.et-fixed-header & {
			margin: -14px 0 10px 0;
		}
	}

	@include responsive('tablet', 'max') {
		margin: 0;

		.et-fixed-header & {
			margin: 0;
		}
	}
}

#et_mobile_nav_menu {
	display: none;
}

#pl-work-time {
	margin-top: 3px;

	@include responsive('tablet-wide', 'max') {
		margin-top: 4px !important;
	}

	@media only screen and (max-width: 1024px) and (min-width: 768px) {
		margin-top: 4px !important;
	}

	p {
		font-size: 14px !important;
		font-weight: bold;
	}

	.phone-number {
		font-size: 16px;
	}
}

.pl-header-btn {
	position: relative;

	@include responsive('tablet-wide', 'max') {
		margin: 0;
	}

	.pl-header-btn-icon,
	.pl-header-btn-text {
		@include inline-block;
	}

	.pl-header-btn-icon {
		margin-right: 5px;
		font-size: 30px;

		@include responsive('tablet', 'max') {
			display: block;
			margin-right: 0;

			.pl-icon-phone {
				&:before {
					display: block;
				}
			}
		}
	}

	.pl-header-btn-text {
		@include responsive('tablet', 'max') {
			display: none;
		}
	}

	.pl-btn-simple {
		padding: 5px;
		text-align: left;
		font-weight: 400;
		box-shadow: none;
	}

	.pl-header-btn-text-main,
	.pl-header-btn-text-sub {
		display: block;
	}

	.pl-header-btn-text-main {
		font-size: 18px;
		margin-bottom: 5px;
	}

	.pl-header-btn-text-sub {
		font-size: 12px;
	}
}

#et-footer-nav {
	.container {
		@media (min-width: 600px) {
			display: flex;
			align-items: baseline;
		}
	}

	.copyright {
		line-height: 18px;

		@media (min-width: 600px) {
			margin-left: auto;
		}
	}
}

.bottom-nav {
	//@extend %pl-menu-link-separator-vertical;
	@extend %pl-menu-link-hover-border;

	text-align: left;

	@media (min-width: 600px) {
		width: 50%;
	}

	li {
		line-height: 1.5;

		&:nth-child(2n) {
			padding-right: 0 !important;
		}

		@media (min-width: 600px) {
			float: left;
			width: 50%;
		}
	}
}

.pl-divi-row-2-8-2.et_pb_row {
	.et_pb_column {
		width: 100% / 3 * 2;
		margin: 0 auto !important;
		float: none;
		text-align: center;
		background-image: -moz-linear-gradient( 90deg, rgb(35,16,0) 0%, rgba(23,11,0,0) 100%);
		background-image: -webkit-linear-gradient( 90deg, rgb(35,16,0) 0%, rgba(23,11,0,0) 100%);
		background-image: -ms-linear-gradient( 90deg, rgb(35,16,0) 0%, rgba(23,11,0,0) 100%);
		border-radius: 0 0 5px 5px;
		padding: 0 10px 10px 10px;
	}
}

.pl-divi-text-solaranlage {
	@include inline-block;
	padding: 15px 60px;
	border-radius: $border-radius-base;
	max-width: 100%;

	@include responsive('tablet', 'max') {
		padding: 15px 30px;
	}

	p:first-child {
		text-transform: uppercase;
		font-size: 23px;
		font-weight: bold;
		padding-bottom: 0;
	}
}

.pl-text-price {
	color: $brand-primary;
	font-size: 78px;
	font-weight: bold;
	line-height: 1;

	@include responsive('tablet', 'max') {
		font-size: 58px;
	}

	sup {
		font-size: 41px;
		vertical-align: bottom;

		@include responsive('tablet', 'max') {
			font-size: 31px;
		}
	}
}

.pl-divi-text-offer-subtext {
	width: 80%;
	margin: 0 auto !important;
	line-height: 1.5;
}

.pl-divi-text-bekannt-aus {
	position: absolute;
	right: 0;
	top: -44px;
	margin-bottom: 0 !important;

	img {
		vertical-align: text-top;
		margin-left: 10px;
	}

	& + .et_pb_text {
		margin-top: 20px;
	}

	@media only screen and (max-width: 1024px) and (min-width: 768px) {
		top: -34px;
	}
}

.pl-divi-blurb-benefit {
	.et_pb_blurb_content {
		max-width: 80%;
	}

	.et_pb_main_blurb_image {
		min-height: 70px;
	}
}

.pl-divi-column-carousel-testimonials {
	.et_pb_testimonial {
		border: 1px solid $table-border-color;
		padding: 20px;
	}
}

.pl-divi-column-carousel-partners {
	.owl-stage {
		align-items: center;
	}
}

.pl-carousel {
	.owl-stage {
		display: flex;
		flex-wrap: wrap;
	}

	.owl-item {
		float: none;
		display: flex;
	}

	.owl-dots {
		margin-top: 40px;
	}

	.owl-prev,
  .owl-next {
		margin-top: -30px;
	}
}

.pl-divi-section-map {
	.pl-divi-row-map-info {
		.et_pb_button_module_wrapper {
			@include responsive('tablet', 'max') {
				padding: 30px 10% 30px 10%;

				.pl-btn-transparent {
					font-size: 16px;
					width: 100%;
					color: $heading-color !important;

					&:hover {
						color: #fff !important;
					}
				}
			}

			@include responsive('tablet') {
				padding: 0;
			}
		}
	}

	.pl-divi-map-address {
		@include responsive('tablet', 'max') {
			padding: 30px 10%;
		}
	}
}

.pl-divi-column-carousel-team {
	.owl-dots {
		.owl-dot {
			span {
				background-color: #fff;
			}
		}
	}
}

.pl-divi-blurb-team {
	p {
		&:first-child {
			padding-bottom: 0;
		}
	}
}

.section-bg-grey {
	background-color: #f5f5f5;
}

.section-thank-you {
	display: table;
	width: 100%;

	& > article {
		display: table-cell;
    vertical-align: middle;
	}

	.container {
		text-align: center;
		padding: 0 !important;
	}
}

#et-footer-nav {
	@include responsive('tablet', 'max') {
		padding: 25px 0;
	}
}

.help-block.form-error {
	display: none;
}

.page-terminanfrage,
.page-vielen-dank {
	#main-header {
		@include responsive('tablet', 'max') {
			.container {
				padding-bottom: 110px !important;
			}

			.logo_container {
				a {
					right: 0;
					text-align: center;
				}

				#logo {
					max-width: 200px;
				}
			}
		}
	}
}