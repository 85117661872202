//
// Lists
// --------------------------------------------------

// Variables
//** Inline list items gutter
$list-inline-gutter:            15px;

// Lists
//
// Various lists styles.
//
// Markup:
// <ul class="pl-list"><li>Lorem ipsum</li><li>Dolor amet</li><li>Lorem ipsum</li></ul>
//
// Styleguide Base.lists
.pl-list,
.pl-list ul,
.pl-list ol {
  padding: 0 !important;
  list-style: none !important;
}

// Inline list
//
// Inline list styles.
//
// Markup:
// <ul class="pl-list pl-list-inline"><li>Lorem ipsum</li><li>Dolor amet</li><li>Lorem ipsum</li></ul>
//
// Styleguide Base.lists.inline
.pl-list-inline,
.pl-list-inline ul,
.pl-list-inline ol {
  @extend .pl-list;
  word-spacing: -.36em;
  display: table; // webkit support

  li {
    @include inline-block;
    word-spacing: normal;
    margin: 0 $list-inline-gutter $list-inline-gutter 0 !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

// Check list
//
// Check list styles.
//
// Markup:
// <ul class="pl-list pl-list-check"><li>Lorem ipsum</li><li>Dolor amet</li><li>Lorem ipsum</li></ul>
//
// Styleguide Base.lists.check
.pl-list-check,
.pl-list-check ul,
.pl-list-check ol {
  //** Check list icon font-size
  $list-check-icon-size:            20px;

  @extend .pl-list;

  li {
    margin: 0 0 $list-inline-gutter 0;

    &:last-child {
      margin: 0;
    }

    &:before {
      content: "\4e";
      font-family: $font-divi-icons;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      font-size: $list-check-icon-size;
      color: $brand-primary;
    }
  }
}

// List with box-like items
//
// Box-like list items styles.
//
// Markup:
// <ul class="pl-list-boxes"><li><a href="#">Lorem</a></li><li><a href="#">ipsum</a></li><li><a href="#">Dolor</a></li><li><a href="#">amet</a></li></ul>
//
// Styleguide Base.lists.box-like
.pl-list-boxes,
.pl-list-boxes ul,
.pl-list-boxes ol {
  //** Boxed-style list gutter
  $list-boxes-gutter:                  $list-inline-gutter;
  //** Boxed-style list item padding
  $list-boxes-item-padding:            10px;
  //** Boxed-style list item border radius
  $list-boxes-item-border-radius:      0;
  //** Boxed-style list item box shadow
  $list-boxes-item-box-shadow:         none; // 1.176px 1.618px 2px 0px rgba($text-color, 0.07)
  //** Boxed-style list item box shadow
  $list-boxes-item-box-shadow-hover:   none; // 1.176px 1.618px 2px 0px rgba($brand-primary, 0.07)

  @extend .pl-list-inline;

  a {
    padding: $list-boxes-item-padding;
    color: $text-color !important;
    display: block;
    border: 1px solid $table-border-color;
    border-radius: $list-boxes-item-border-radius;
    box-shadow: $list-boxes-item-box-shadow;
    @include transition;
  
    &:hover {
      color: $brand-primary !important;
      border-color: $brand-primary;
      box-shadow: $list-boxes-item-box-shadow-hover;
    }

    @include responsive('tablet', 'max') {
      font-size: $text-font-size-mobile;
    }
  }
}

// List with 2 items in row
//
// Description here
//
// Markup:
// <ul class="pl-list-2-in-row"><li><a href="#">Lorem</a></li><li><a href="#">ipsum</a></li><li><a href="#">Dolor</a></li><li><a href="#">amet</a></li></ul>
//
// Styleguide Base.lists.2-in-row
.pl-list-2-in-row,
.pl-list-2-in-row ul,
.pl-list-2-in-row ol {
  @extend .pl-list-inline;
  @include list-columns(2, $list-inline-gutter);
}

// List with 3 items in row
//
// Description here
//
// Markup:
// <ul class="pl-list-3-in-row"><li><a href="#">Lorem</a></li><li><a href="#">ipsum</a></li><li><a href="#">Dolor</a></li><li><a href="#">amet</a></li></ul>
//
// Styleguide Base.lists.3-in-row
.pl-list-3-in-row,
.pl-list-3-in-row ul,
.pl-list-3-in-row ol {
  @extend .pl-list-inline;
  @include list-columns(3, $list-inline-gutter);
}

// List with 4 items in row
//
// Description here
//
// Markup:
// <ul class="pl-list-4-in-row"><li><a href="#">Lorem</a></li><li><a href="#">ipsum</a></li><li><a href="#">Dolor</a></li><li><a href="#">amet</a></li></ul>
//
// Styleguide Base.lists.4-in-row
.pl-list-4-in-row,
.pl-list-4-in-row ul,
.pl-list-4-in-row ol {
  @extend .pl-list-inline;
  @include list-columns(4, $list-inline-gutter);
}