//
// Tables
// --------------------------------------------------

// Variables
//** Table cells padding
$table-padding:             10px !default;

// Tables
//
// Basic tables styles.
//
// Markup:
// <table class="pl-table"><tr><td>1</td><td>2</td><td>3</td></tr><tr><td>4</td><td>5</td><td>6</td></tr><tr><td>7</td><td>8</td><td>9</td></tr></table>
//
// Styleguide Base.tables

.pl-table,
.pl-table table {
  width: 100%;
  height: auto !important;
  border: none;
  margin: 0;

  td {
    padding: $table-padding !important;
    border-top: 1px solid $table-border-color !important;
  }

  tr:first-child td {
    border-top: none !important;
  }
}