//
// Forms
// --------------------------------------------------

label {
	@include inline-block;
  color: $text-color;
  font-size: $input-label-font-size;
  font-weight: $heading-font-weight;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="search"],
textarea,
select {
  width: 100%;
  color: $input-text-color;
  font-family: $font-default;
	font-size: $form-text-font-size;
	font-weight: $form-text-font-weight !important;
  padding: $input-padding;
  border: 1px solid $input-border-color;
  border-radius: $border-radius-none;
  box-shadow: inset 0px 2px 0px 0px rgba($input-border-color, 0.08);
  background-color: $input-bg-color;
	@include transition;
	@include placeholder;

	@include responsive('tablet', 'max') {
		font-size: $form-text-font-size * 0.9;
	}
	
	&.error {
		border-color: $form-validation-error-color;
		background-color: $form-validation-error-bg-color;
	}
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="search"],
select {
	line-height: $form-text-line-height;

	&:focus {
		border-color: $input-border-hover-color !important;
		background-color: $input-bg-color !important;
		color: $input-text-color;
  	outline: none;
	}

	&.datepicker {
	  background: url(../img/icon_calendar.png) #fff left 10px center no-repeat;
	  background-size: 18px;
	  padding-left: 38px;
	}

	&.timepicker {
	  background: url(../img/icon_clock.png) #fff left 10px center no-repeat;
	  background-size: 18px;
	  padding-left: 38px;
	}
}

textarea {
	line-height: $textarea-line-height;
	max-width: 100%;

	&:focus {
		border-color: $input-border-hover-color;
		background-color: $input-bg-color !important;
		color: $input-text-color;
		outline: none;
	}
}

select {
	appearance: none;
  background: url(../img/icon_select_arrow.png) #fff right 10px center no-repeat;
	background-size: 10px;

	&.select-disabled {
		color: $input-placeholder-color;
	}
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
	left: -9999px;
	
	&.error {
		& + label {
			&:before {
				border-color: $form-validation-error-color;
				outline: none;
			}
		}
	}

  & + label,
  &:checked + label {
  	position: relative;
	  padding-left: 40px;
	  cursor: pointer;
	  margin-left: 0;
	  display: inline-block;
	  font-size: $form-text-font-size;
	  color: $text-color;

	  &:before {
	  	content: '';
		  position: absolute;
		  left: 0;
		  width: $checkbox-box-size;
		  height: $checkbox-box-size;
		  box-shadow: inset 0px 2px 0px 0px rgba($input-border-color, 0.08);
		  border: $checkbox-box-border-width solid $input-border-color;
		  border-radius: $border-radius-none;
		  background-color: $input-bg-color;
		  outline: none;
	  }

	  &:after {
	  	content: '';
		  position: absolute;
		  @include transition;
	  }
  }
}

//TODO check line height
input[type="checkbox"] {
	& + label,
	&:checked + label {
		line-height: 26px;

		// TODO why top?
		&:before {
			// top: 4px;
		}

		&:after {
			width: $checkbox-box-size;
		  height: $checkbox-box-size;
		  top: 0; 
		  left: 0;
		  content: '\4e';
		  font-family: 'ETmodules';
		  font-size: $checkbox-icon-font-size;
		  line-height: $checkbox-box-height;
		  color: $brand-primary;
		  text-align: center;
		}
	}

	& + label {
		margin-bottom: 0;

		&:after {
			opacity: 0;
	  	transform: scale(0);
	  }
	}

	&:checked + label:after {
		opacity: 1;
  	transform: scale(1);
	}
}

input[type="radio"] {
	& + label,
	&:checked + label {
		line-height: $checkbox-box-height;

		&:before {
			top: 0;
  		border-radius: $border-radius-none;
		}

		&:after {
			width: $radio-checked-circle-size;
		  height: $radio-checked-circle-size;
		  top: $radio-checked-circle-gap;
		  left: $radio-checked-circle-gap;
		  border-radius: $border-radius-circle;
		  background-color: $brand-primary;
		}
	}

	& + label {
		&:after {
			opacity: 0;
	  	transform: scale(0);
	  }
	}

	&:checked + label:after {
		opacity: 1;
  	transform: scale(1);
	}
}

.form-note {
	font-size: 12px;
	padding-bottom: 20px;
}

// Remove arrows in input[type="number"]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}