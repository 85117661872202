//
// Divi | Common styles
// --------------------------------------------------

// Variables
$main-content-padding:                  54px;
$main-content-padding-mobile:           30px;

// True fullwidth divi row
.pl-divi-row-fullwidth.et_pb_row {
  width: 100%;
  max-width: 100%;
}

.et_pb_row {
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @include responsive('tablet-wide', 'max') {
    .et_pb_column {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.pl-divi-row-two-columns {
    $gutter:            20px;
    $column-width:      calc(50% - (#{$gutter} / 2));

    .et_pb_column {
      margin-right: $gutter !important;
      margin-bottom: 0 !important;
      width: $column-width !important;

      &:last-child {
        margin-right: 0 !important;
      }

      @include responsive('tablet', 'max') {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: $gutter !important;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &.pl-divi-row-three-columns {
    $gutter:            20px;
    $column-width:      calc(33.333% - (#{$gutter} * 2 / 3));

    .et_pb_column {
      margin-right: $gutter !important;
      margin-bottom: 0 !important;
      width: $column-width !important;

      &:last-child {
        margin-right: 0 !important;
      }

      @include responsive('tablet', 'max') {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: $gutter !important;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &.pl-divi-row-four-columns {
    $gutter:            20px;
    $column-width:      calc(25% - (#{$gutter} * 3 / 4));
    $columns-1-1-width: calc(50% - (#{$gutter} / 2));

    padding-bottom: $gutter;

    @include responsive('tablet-wide', 'max') {
      padding-bottom: 0;
    }

    .et_pb_column {
      margin-right: $gutter !important;
      margin-bottom: 0 !important;
      width: $column-width !important;

      &:last-child {
        margin-right: 0 !important;
      }

      @include responsive('tablet', 'max') {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: $gutter !important;
      }

      @include responsive('tablet-wide', 'max') {
        width: $columns-1-1-width !important;
        margin-bottom: $gutter !important;

        &:nth-child(2n) {
          margin-right: 0 !important;
        }
      }
    }
  }
}

#main-content {
  .container {
    padding: $main-content-padding 0;

    @include responsive('tablet-wide', 'max') {
      padding: $main-content-padding-mobile 0;
    }
  }

  #left-area {
    padding-bottom: 0;
  }
}

#comment-wrap {
  padding-top: $main-content-padding;
}

#respond {
  padding-top: 0;
}

#commentform {
  @include clearfix;
  padding-bottom: 0;

  textarea {
    @extend textarea;
    display: block;
  }

  input[type=text],
  input[type=email],
  input[type=url] {
    background-color: $input-bg-color;
    color: $input-text-color;
    @include placeholder;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    font-size: $form-text-font-size;
  }

  input[type="submit"] {
    @extend .pl-btn-simple;

    @include responsive('tablet', 'max') {
      width: 100% !important;
    }
  }

  .form-submit {
    @include responsive('tablet', 'max') {
      float: none;
    }
  }
}

.et_full_width_page #sidebar {
  display: none;
}

.et_pb_scroll_top.et-pb-icon {
  background: rgba($brand-primary, 0.7);
}

// Divi Lightbox image captions
.mfp-title {
  display: none;
}

.mobile_menu_bar:before {
  color: $brand-primary;
}

.et_mobile_menu {
  border-color: $brand-primary;
}

.pl-divi-row-border-right {
	.et_pb_column {
    border-right: 1px solid $table-border-color;
    
    @include responsive('tablet-wide', 'max') {
      border-right: 0;
      border-bottom: 1px solid $table-border-color;
      padding: 30px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

		&:last-child {
			border-right: none;
		}
	}
}

.et_pb_column {
  &.pl-divi-column-inline {
    @include inline-block();

    &:nth-child(n+2) {
      margin-left: -4px;
    }

    @include responsive('tablet-wide', 'max') {
      margin-left: 0 !important;
    }
  }

  &.pl-divi-column-width-auto {
    width: auto !important;

    @include responsive('tablet-wide', 'max') {
      width: 100% !important;
    }
  }
}

.single {
  .et_pb_post {
    margin-bottom: 0;
  }

  .post {
    padding-bottom: 0;
  }
}

.et_pb_post {
  &.pl-last-post {
    margin-bottom: 0;
  }
}

.pagination {
  margin-top: $main-content-padding;
  
  @include responsive('tablet-wide', 'max') {
    margin-top: $main-content-padding-mobile;
  }

  .alignleft,
  .alignright {
    a {
      @extend .pl-btn-transparent;
    }
  }
}

.gallery {
	word-spacing: -.36em;
	display: table; // webkit support
	width: 100%;

	.gallery-caption,
	.gallery-item a {
		width: 100%;
		margin: 0;
		text-align: center;
		font-size: 0.8em;
	}

	dl {
		margin: 0;

		dt {
			text-align: center;
		}
	}

	.gallery-item {
		@include inline-block;
    word-spacing: normal;

		@include responsive('tablet', 'max') {
      width: 100% !important;
			margin: 0 0 15px 0 !important;
			
			&:last-child {
				margin: 0 !important;
			}
    }
	}
}

.gallery-columns-4 {
	.gallery-item {
		width: calc(100% / 4 - 3 * 10px / 4);
		margin: 0 10px 0 0;
		
		&:nth-child(4) {
			margin: 0;
		}
	}
}

.gallery-columns-5 {
	.gallery-item {
		width: calc(100% / 5 - 4 * 10px / 5);
		margin: 0 10px 0 0;
		
		&:nth-child(5) {
			margin: 0;
		}
	}
}

.gallery-columns-6 {
	.gallery-item {
		width: calc(100% / 6 - 5 * 10px / 6);
		margin: 0 10px 0 0;
		
		&:nth-child(6) {
			margin: 0;
		}
	}
}