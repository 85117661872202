//
// Footer
// --------------------------------------------------

//** Variables
$footer-padding: 						20px;
$footer-nav-position:				left;
$footer-copyright-position: right;
$footer-text-font-weight:		bold;

#footer-bottom,
.bottom-nav {
  padding: 0;
}

#footer-info {
	&:empty {
		padding: 0;
	}
}

#et-footer-nav {
	padding: $footer-padding 0;

	@include responsive('tablet', 'max') {
		padding: ($footer-padding * 0.75) 0;
	}
	
	.container {
		@include clearfix();
	}

	.copyright {
		font-size: 16px;
		font-weight: $footer-text-font-weight;
		line-height: 1;
		float: $footer-copyright-position;
		color: #fff;

		@include responsive('tablet', 'max') {
			float: none;
			text-align: center;
			margin-top: 30px;
		}
	}

	.et-social-icons {
		a {
			color: $text-color !important;

			&:hover {
				color: $brand-primary !important;
			}
		}

		@include responsive('tablet', 'max') {
			padding-left: 0;
			padding-top: 20px;

			& li:first-child {
				margin-left: 0;
			}
		}
	}
}

.bottom-nav {
	text-align: center;
	float: $footer-nav-position;
	//line-height: 1;

	@include responsive('tablet', 'max') {
		float: none;
	}

	li {
		//padding-right: 30px;
		font-weight: $footer-text-font-weight;

		&:last-child {
			padding-right: 0;
		}

		&.current-menu-item a {
			// border-color: $brand-primary;
		}

		@include responsive('tablet', 'max') {
			display: block;
			text-align: center;
			padding-right: 0;
			padding-bottom: 15px;

			&:last-child {
				padding-bottom: 0;
			}
		}

		a {
			@include inline-block();

      &:hover {
				color: #fff;
				opacity: 1;
      }
		}
	}
}