// Components
//
// Components are ingredients of our design system. They may be made up of smaller groups of styles.
//
// Styleguide Components

// Buttons
// 
// Various button styles.
//
// Styleguide Components.buttons

//** Variables
$btn-font-family:         $font-heading-default !default;
$btn-width:        			  auto !default;
$btn-padding:             12px 20px;
$btn-transparent-padding: 12px 20px !default;
$btn-font-size:           22px !default;
$btn-font-weight:         500 !default;
$btn-color:               #fff !default;
$btn-bg:                  $brand-primary !default;
$btn-hover-bg:            $brand-primary-lighter !default;
$btn-hover-color:         #fff !default;
$btn-letter-spacing:      0 !default;
$btn-text-decoration:     none;
$btn-border-width:        2px !default;

// Placeholder for all buttons
%pl-btn {
  @include inline-block;
  @include transition;
  border-radius: $border-radius-none;
  line-height: 1 !important;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  letter-spacing: $btn-letter-spacing;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  text-transform: $btn-text-decoration;
  cursor: pointer;

  &::after {
    display: none;
  }
}

// Simple
// 
// Basic style.
//
// Markup:
// <button class="pl-btn-simple {{modifier_class}}">Simple</button>
//
// :hover - When user hovers over button.
//
// Styleguide Components.buttons.simple
.pl-btn-simple,
.pl-btn-simple.et_pb_button {
  $btn-simple-box-shadow:      0px 4px 0px 0px #cd7707;   
  @extend %pl-btn;

  background-color: $btn-bg;
  border: $btn-border-width solid $btn-bg;
  color: $btn-color !important;
  padding: $btn-padding;
  box-shadow: $btn-simple-box-shadow;

  &:hover {
    background-color: $btn-hover-bg;
    padding: $btn-padding;
    border-width: $btn-border-width;
    border-color: $btn-hover-bg;
  }
}

// Transparent
// 
// Button with transparent bg, border and primary color
//
// Markup:
// <button class="pl-btn-transparent {{modifier_class}}">Transparent</button>
//
// :hover - When user hovers over button.
// .pl-btn-black - Black style
// .pl-btn-white - White style
//
// Styleguide Components.buttons.transparent
.pl-btn-transparent,
.pl-btn-transparent.et_pb_button {
  @extend %pl-btn;

  border: $btn-border-width solid $btn-bg;
  background-color: transparent;
  color: $brand-primary !important;
  padding: $btn-transparent-padding;

  &:after {
    color: $brand-primary;
  }

  &:hover {
    border-color: $btn-bg;
    background-color: $btn-bg;
    color: $btn-hover-color !important;
    padding: $btn-transparent-padding;

    &:after {
      color: $btn-hover-color !important;
    }
  }

  &.pl-btn-black {
    border-color: #000;
    color: #000 !important;

    &:hover {
      border-color: #000;
      background-color: #000;
      color: #fff !important;
    }
  }

  &.pl-btn-white {
    border-color: #fff;
    color: #fff !important;

    &:before {
      color: #fff !important;
    }

    &:hover {
      background-color: #fff !important;
      color: $heading-color !important;

      &:before {
        color: $heading-color !important;
      }
    }
  }
}

.pl-btn-fullwidth,
.pl-btn-fullwidth.et_pb_button {
  width: 100%;
}

.pl-btn-icon,
.pl-btn-icon.et_pb_button {
  &,
  &:hover {
    padding: 0;
  }
}

.pl-btn-with-left-icon,
.pl-btn-with-left-icon.et_pb_button {
  &:before {
    display: inline-block;
    position: static;
    font-family: $font-divi-icons;
    font-size: inherit;
    margin: 0 10px 0 0;
    transition: none;
    opacity: 1;
    line-height: 1;
  }
}

.pl-btn-with-icon,
.pl-btn-with-icon.et_pb_button {
  &,
  &:hover {
    padding: $btn-transparent-padding;
  }

  &::after {
    content: "\24";
    position: absolute;
    opacity: 1;
    margin-left: 0;
    right: 20px;
    font-family: $font-divi-icons;
    font-weight: normal;
    font-size: 20px;
    @include absolute-align(y);
  }
}
