//
// Owl Carousel
// --------------------------------------------------

@import "vendor/owl.carousel.min";

//== Variables

//** Nav arrows size
$carousel-arrows-size:						40px !default;
$carousel-arrows-gap:             20px !default;
//** Nav arrows size
$carousel-arrows-size-mobile:			30px !default;
$carousel-arrows-bg-color:			  $brand-primary !default;
//** Nav arrows color
$carousel-arrows-color:						#fff !default;
//** Dots color
$carousel-dots-color:						  #f5f5f5 !default;
//** Dots active & hover color
$carousel-dots-active-color:			$brand-primary !default;
//** Dots width
$carousel-dots-width:			        20px !default;
//** Dots height
$carousel-dots-height:			      20px !default;
//** Dots margin
$carousel-dots-margin:			      10px !default;
//** Dots border radius
$carousel-dots-border-radius:			$border-radius-none !default;

//== Common styles

.pl-carousel {
  // padding: 0 30px;
  position: relative;

  .owl-item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);

    // merge
    img {
      //width: auto;
      margin: 0 auto;
    }
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    @include absolute-align(y);
    font-size: $carousel-arrows-size;
    line-height: 1;
    background-color: $carousel-arrows-bg-color;
    color: $carousel-arrows-color;
    @include transition;

    span {
      display: block;

      &:before {
        display: block;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .owl-prev {
    left: -$carousel-arrows-size - $carousel-arrows-gap;
  }

  .owl-next {
    right: -$carousel-arrows-size - $carousel-arrows-gap;
  }

  .owl-dots {
    text-align: center;
    margin-top: 10px;
    line-height: 1;

    .owl-dot {
      @include inline-block;
      margin: 0 $carousel-dots-margin $carousel-dots-margin 0;

      &:last-child {
        margin-right: 0;
      }

      &.active span,
      &:hover span {
        background-color: $carousel-dots-active-color;
      }

      span {
        width: $carousel-dots-width;
        height: $carousel-dots-height;
        background-color: $carousel-dots-color;
        display: block;
        border-radius: $carousel-dots-border-radius;
        @include transition;
      }
    }
  }
}