//
// Wordpress | Contact Form 7
// --------------------------------------------------

// Variables
$wpcf7-response-error-output-border-color: $form-validation-error-border-color;
$wpcf7-response-error-output-bg-color: 		 $form-validation-error-bg-color;
$wpcf7-response-error-output-color: 			 $form-validation-error-color;
$wpcf7-response-ok-output-border-color: 	 $brand-primary;
$wpcf7-response-ok-output-bg-color: 			 $input-bg-color;
$wpcf7-response-ok-output-color: 					 $brand-primary;

div.wpcf7 {
	textarea {
		display: block;
	}

	input[type="submit"] {
		@extend .pl-btn-simple;
	}

	.pl-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.pl-col-xs-12 {
			@include responsive('tablet', 'max') {
				margin: 0 0 20px 0;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.wpcf7-form-control-wrap {
		display: block;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.accept {
		.wpcf7-not-valid-tip {
			display: block;
		}
	}

	.wpcf7-not-valid {
		border-color: $wpcf7-response-error-output-border-color;
		background-color: $form-validation-error-bg-color;
	}

	.wpcf7-radio span.wpcf7-list-item.first,
	.wpcf7-checkbox span.wpcf7-list-item.first {
		margin: 0;
	}

	.wpcf7-response-output {
		clear: both;
		margin: 20px 0 0 0;
		padding: 10px;
		text-align: center;

		&.wpcf7-validation-errors {
			border: 1px solid $wpcf7-response-error-output-border-color;
			color: $wpcf7-response-error-output-color;
			background-color: $wpcf7-response-error-output-bg-color;
		}

		&.wpcf7-mail-sent-ok {
			border: 1px solid $wpcf7-response-ok-output-border-color;
			color: $wpcf7-response-ok-output-color;
			background-color: $wpcf7-response-ok-output-bg-color;
		}
	}

	.wpcf7-not-valid-tip {
		display: none;
	}

	// Requires custom JS function to move ajax-loader before submit button
	.ajax-loader {
		position: relative;
		width: 32px;
		height: 32px;
		clear: both;
		margin: 6px 20px 6px (-32px - 20px); // left margin needs to position submit btn on center
		border: 4px rgba($brand-primary-lighter, 0.5) solid;
		border-top: 4px rgba($brand-primary, 1) solid;
		border-radius: 50%;
		animation: spCircRot .6s infinite linear;
		background-image: none;
	}

	@keyframes spCircRot {
		from { transform: rotate(0deg); }
		to { transform: rotate(359deg); }
	}
}

.wpcf7-form {
	@include clearfix();
}