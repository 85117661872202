//
// Typography
// --------------------------------------------------

// Variables
//** H1 font-size
$h1-font-size:             34px !default;
//** H2 font-size
$h2-font-size:             38px !default;

h1, h2, h3, h4, h5, h6 {
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  font-family: $font-default;

  &:last-child {
    padding-bottom: 0;
  }
}

h1 {
  font-size: $h1-font-size;

  @include responsive('tablet-wide', 'max') {
    font-size: $h1-font-size * 0.85;
  }

  @include responsive('tablet', 'max') {
    font-size: $h1-font-size * 0.7;
  }
}

h2 {
  font-size: $h2-font-size;

  @include responsive('tablet-wide', 'max') {
    font-size: $h2-font-size * 0.85;
  }

  @include responsive('tablet', 'max') {
    font-size: $h2-font-size * 0.7;
  }
}

// h3 {
//   font-size: 30px;

//   @include responsive('tablet', 'max') {
//     font-size: 20px;
//   }

//   @include responsive('tablet-wide', 'max') {
//     font-size: 26px;
//   }
// }

// h4 {
//   font-size: 24px;

//   @include responsive('tablet', 'max') {
//     font-size: 18px;
//   }

//   @include responsive('tablet-wide', 'max') {
//     font-size: 20px;
//   }
// }

.pl-font-bold {
  font-weight: bold;
}

.pl-font-normal {
  font-weight: normal;
}

.pl-text-weight-300 {
  font-weight: 300 !important;
}

.pl-text-weight-400 {
  font-weight: 400 !important;
}

.pl-text-weight-500 {
  font-weight: 500 !important;
}

.pl-text-weight-900 {
  font-weight: 900 !important;
}