//
// WP | Yoast Breadcrumbs
// --------------------------------------------------

//** Variables
$page-header-bg-color: #f2f2f2;
$page-header-padding: 10px 0;
$breadcrumbs-font-size: 14px;
$breadcrumbs-separator-padding: 10px;

// Require new action in page.php
.pl-page-header {
  background-color: $page-header-bg-color;
  padding: $page-header-padding;
}

// Yoast breadcrumbs
// Require add_action with breadcrumbs
.pl-yoast-breadcrumbs {
  line-height: 1;

  & > span {
    @include inline-block;
    line-height: 1;

    & > span {
      @include inline-block;
      line-height: 1;

      & > a {
        padding-left: 0;
      }

      a,
      span {
        display: inline;
        font-size: $breadcrumbs-font-size;
        line-height: 1.5;
      }
    }
  }
  
  // Require 
  // Yoast plugin settings option "Separator between breadcrumbs" with value
  // <span class="pl-breadcrumbs-separator">/</span>
  .pl-breadcrumbs-separator {
    padding: 0 $breadcrumbs-separator-padding;
  }
  
  .et-fixed-header &,
  .page-home & {
    display: none;
  }
}