//
// Section | Checkout
// --------------------------------------------------

.form-checkout {
  margin: 0;
  padding: $checkout-form-padding;
  background-color: #fff;
  border-radius: $border-radius-base;

  .form-heading {
    color: $heading-color !important;
    padding: 0 0 $checkout-row-padding 0;
    // border-bottom: 1px solid $input-border-color;
  }

  .form-section {
    padding-bottom: $checkout-row-padding;
    // border-bottom: 1px solid $input-border-color;

    &.form-section-option {
      & h5 {
        display: inline-block;
        padding-right: 5px;
      }

      .benefits-more {
        display: inline-block;
        line-height: 1;
        color: $brand-primary;

        &:hover,
        &:focus,
        &:active {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }

    &.form-section-submit {
      // padding: $checkout-form-padding 0 0;
      border-bottom: none;
    }
  }

  .pl-row {
    margin-bottom: $checkout-row-padding;

    &:last-child {
      margin-bottom: 0;
    }

    &.pl-row-heading {
      padding: $checkout-row-padding 0;

      &:last-child {
        margin-top: 0;
      }

      & h5 {
        margin: 0;
        font-weight: $heading-font-weight;
        font-size: $checkout-row-heading-font-size;
        padding-bottom: 0;
      }

      & p {
        margin-top: 10px;
        color: $text-color;
      }
    }

    &.option-benefits {
      margin-bottom: 0;
    }
  }

  .btn-submit {
    @include responsive('tablet', 'max') {
      width: 100%;
    }
  }

  .pl-col-xs-12 {
    @include responsive('tablet', 'max') {
      margin-bottom: $checkout-row-padding;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //TODO check this class
  & .first-radio-line {
    margin-bottom: 10px;
  }
}

//TODO remove this old class
.agb-warning {
  display: none;
  color: $form-validation-error-color;
  margin-top: 10px;
}

.checkout-info {
  .checkout-info-heading {
    padding: $checkout-row-padding;
    border-radius: $border-radius-base $border-radius-base 0 0;
    text-align: left;
    position: relative;
    background-color: $checkout-info-header-bg-color;

    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translate(-50%, 0);
      transform: translate3d(-50%, 0, 0);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 15px;
      border-color: $checkout-info-header-bg-color transparent transparent transparent;
    }

    & h3 {
      text-align: center;
      color: $heading-color;
      padding-bottom: $checkout-row-padding;
    }

    & .tariff-offer-inner {
      display: block;
      text-align: center;
    }

    & > div + div {
      margin-left: -4px;
    }
  }

  .checkout-info-body {
    border-radius: 0 0 $border-radius-base $border-radius-base;
    background-color: #fff;
    padding: 30px $checkout-row-padding $checkout-row-padding;
    font-size: $form-text-font-size;
    text-align: center;

    & img {
      max-width: 300px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

//TODO Rebuild this price block
.checkout-info-heading.tariff-offer .tariff-offer-inner > p {
  font-size: 11px;
  margin-bottom: 0;
  color: #313133;
  padding-bottom: 0;
  line-height: 1;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price > span {
  display: inline-block;
  vertical-align: middle;
  line-height: 44px;
  font-weight: bold;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price > span > span {
  display: block;
  line-height: 1;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price .price-big {
  font-size: 48px;
  font-weight: bold;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price .price-small {
  /*color: #a58e0a;*/
  font-size: 12px;
  /*letter-spacing: 2px;*/
  line-height: 1;
  padding-top: 5px;
  font-weight: bold;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price .price-cents sup {
  font-size: 10px;
  vertical-align: top;
  left: 4px;
  bottom: auto;
  top: 0;
}

.checkout-info-heading.tariff-offer .tariff-offer-inner .tariff-price .tariff-currency {
  line-height: 1;
  padding-top: 25px;
  text-transform: none;
  font-size: 16px;
}