//
// Different effects
// --------------------------------------------------

// Effect for menu link - when hover(and current page) show animated stripe under link
%pl-menu-link-hover-stripe {
	li {
		&.current-menu-item a:before,
		&.current_page_item a:before {
      @include pseudo();
      z-index: 2;
      left: 0;
      right: 0;
		}

		&.menu-item-has-children {
			a:before {
				display: none;
			}
		}

		a {
			&:before {
				@include pseudo();
				z-index: -2;
				left: 0;
				right: 100%;
				bottom: 50%;
				background: $brand-primary;
				height: 3px;
				transition-property: right;
				transition-duration: 0.3s;
        transition-timing-function: ease-out;
        
        .et-fixed-header & {
          bottom: 35%;
        }
			}

			&:hover {
				opacity: 1 !important;

				&:before {
					right: 0;
				}
			}
		}
	}
}

// Show vertical separator between links
%pl-menu-link-separator-vertical {
	$separator-color: 				#fff;

	li {
		padding: 0 15px !important;
		border-right: 1px solid $separator-color;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			border-right: none;
			padding-right: 0 !important;
		}

		@include responsive('tablet', 'max') {
			padding: 0 0 15px 0 !important;
			border: none;

			&:last-child {
				padding: 0 !important;
			}
		}
	}
}

// Show border when link hover
%pl-menu-link-hover-border {
	$border-color:						#fff;

	li {
		a {
			border-bottom: 1px solid transparent;

			&:hover {
				border-color: $border-color;
			}
		}
	}
}

// Blinking dots animation
// Require markup like "<p class="blinking">Bitte warten sie <span>.</span><span>.</span><span>.</span></p>"
.blinking {
	span {
		@keyframes blink {
			/**
			 * At the start of the animation the dot
			 * has an opacity of .2
			 */
			0% {
					opacity: .2;
			}
			/**
			 * At 20% the dot is fully visible and
			 * then fades out slowly
			 */
			20% {
					opacity: 1;
			}
			/**
			 * Until it reaches an opacity of .2 and
			 * the animation can start again
			 */
			100% {
					opacity: .2;
			}
		}
		/**
				* Use the blink animation, which is defined above
				*/
		animation-name: blink;
		/**
				* The animation should take 1.4 seconds
				*/
		animation-duration: 1.4s;
		/**
				* It will repeat itself forever
				*/
		animation-iteration-count: infinite;
		/**
				* This makes sure that the starting style (opacity: .2)
				* of the animation is applied before the animation starts.
				* Otherwise we would see a short flash or would have
				* to set the default styling of the dots to the same
				* as the animation. Same applies for the ending styles.
				*/
		animation-fill-mode: both;
		font-size: 20px;

		&:nth-child(2) {
			/**
			 * Starts the animation of the third dot
			 * with a delay of .2s, otherwise all dots
			 * would animate at the same time
			 */
			animation-delay: .2s;
		}

		&:nth-child(3) {
			/**
			 * Starts the animation of the third dot
			 * with a delay of .4s, otherwise all dots
			 * would animate at the same time
			 */
			animation-delay: .4s;
		}
	}
}