//
// Divi module | Slider
// --------------------------------------------------

.et_pb_slider {
  .et-pb-slider-arrows {
    @include responsive('tablet', 'max') {
      display: none;
    }
  }
}

.pl-divi-slider-fullwidth.et_pb_slider {
  .et_pb_slide {
    padding: 0;
    background-color: transparent !important;

    .et_pb_container {
      width: 100%;
      max-width: 100%;
      display: block;
    }

    &.et-pb-active-slide,
    &:first-child {
      display: block !important;

      .et_pb_slide_image {
        &,
        img.active {
          animation-name: fadeIn !important;
          animation-fill-mode: none !important;
          animation-duration: 1.2s !important;
          animation-delay: 0s !important;
        }
      }

      .et_pb_slide_description {
        animation: none !important;
      }
    }
  }

  .et_pb_slide_image {
    width: 100%;
    margin: 0;
    position: static;
    margin-top: 0 !important;

    img {
      max-height: none !important;
    }
  }
}

.pl-divi-slider-header-bottom.et_pb_slider {
  .et_pb_slide {
    .et_pb_slide_description {
      float: none;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 0;

      @include responsive('tablet', 'max') {
        position: static;
      }

      .et_pb_slide_title {
        width: 80%;
        max-width: 1080px;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}