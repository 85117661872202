//
// Mixins
// --------------------------------------------------

// Layout
// @import "mixins/clearfix";

//Placeholder
// @import "mixins/vendor-prefixes";

//Center block
// @import "mixins/center-block";

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Placeholder text
@mixin placeholder($color: $input-placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    text-transform: $input-placeholder-text-transform;
    font-family: $input-placeholder-font-family;
    font-weight: $input-placeholder-font-weight;
  }

  &:-ms-input-placeholder { 
    color: $color;
    text-transform: $input-placeholder-text-transform;
    font-family: $input-placeholder-font-family;
    font-weight: $input-placeholder-font-weight;
  } // Internet Explorer 10+

  &::-webkit-input-placeholder {
    color: $color;
    text-transform: $input-placeholder-text-transform;
    font-family: $input-placeholder-font-family;
    font-weight: $input-placeholder-font-weight;
  } // Safari and Chrome
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Pseudo elements base
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

// Absolutely positioned elements align - horizontal, vertical or both
@mixin absolute-align($direction: center) {
  @if $direction == x {
    left: 50%;
    transform: translate(-50%, 0);
    transform: translate3d(-50%, 0, 0);
  } @else if $direction == y {
    top: 50%;
    transform: translate(0, -50%);
    transform: translate3d(0, -50%, 0);
  } @else if $direction == center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: translate3d(-50%, -50%, 0);
  }
}

// CSS triangles
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
	@include pseudo($pos: $position);
	width: 0;
	height: 0;
	@if $round {
	  border-radius: $border-radius-base;
	}
	@if $direction == down {
	  border-left: $size solid transparent;
	  border-right: $size solid transparent;
	  border-top: $size solid $color;
	  margin-top: 0 - round( $size / 2.5 );
	} @else if $direction == up {
	  border-left: $size solid transparent;
	  border-right: $size solid transparent;
	  border-bottom: $size solid $color;
	  margin-bottom: 0 - round( $size / 2.5 );
	} @else if $direction == right {
	  border-top: $size solid transparent;
	  border-bottom: $size solid transparent;
	  border-left: $size solid $color;
	  margin-right: -$size;
	} @else if  $direction == left {
	  border-top: $size solid transparent;
	  border-bottom: $size solid transparent;
	  border-right: $size solid $color;
	  margin-left: -$size;
	}
}

//TODO
// Media queries breakpoints
$breakpoints: (
  "phone":          320px,
  "iphone-6":       375px,
  "phone-wide":     480px,
  "phablet":        560px,
  "tablet-small":   640px,
  "tablet":         768px,
  "tablet-wide":    1024px,
  "desktop":        1248px,
  "divi-container": 1350px,
  "desktop-wide":   1440px
);

@mixin responsive($width, $type: min, $orientation: '') {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @if $orientation == '' {
      @media only screen and (#{$type}-width: $width) {
        @content;
      }
    } @else {
      @media only screen and (#{$type}-width: $width) and (orientation: $orientation) {
        @content;
      }
    }
  }
}

@mixin bg-cover() {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-size: cover;
}

@mixin responsive_visibility_toggle($hidden, $shown) {
  @include responsive($hidden) {
    display: none;
  }

  @include responsive($shown) {
    display: block;
  }
}

@mixin inline-block($align: '') {
  float: none;
  display: inline-block;
  @if $align == "" {
    vertical-align: middle;
  } @else {
    vertical-align: $align;
  }
}

@mixin divi-container() {
  width: 80%;
  max-width: 1080px;
  margin: 0 auto;
}

@mixin divi-section-padding() {
  padding: 54px 0;

  @include responsive('tablet', 'max') {
    padding: 30px 0;
  }
}

@mixin transition($speed: 0.4s) {
  transition: all $speed ease;
}

@mixin list-columns($columns, $gutter) {
  $list-gutters-count: $columns - 1;
  $list-columns-gutter: $list-gutters-count * $gutter;

  li {
    width: calc(100% / #{$columns} - #{$list-columns-gutter} * #{$list-gutters-count} / #{$columns});
    margin: 0 $list-columns-gutter 0 0;

    @include responsive('tablet', 'max') {
      width: 100%;
      margin: 0 0 $list-columns-gutter 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}