//
// Header
// --------------------------------------------------

$logo-top-mobile:               34px;

.pl-header-btn {
  // @include responsive('phone') {
  //   text-align: center;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 24px;
  //   margin: 0;
  // }

  // @include responsive('iphone-6') {
  //   text-align: right;
  //   left: auto;

  //   .plwt-active & {
  //     bottom: calc(24px + 5px);
  //   }
  // }

  @include responsive('tablet') {
    position: relative;
    float: left;
    width: auto;
    bottom: auto;
    margin-top: -4px;
    text-align: center;

    .plwt-active & {
      bottom: auto;
    }
  }

  @include responsive('tablet-wide') {
    margin-top: 0;
    margin-left: $header-elements-gap;
  }
}

#et_mobile_nav_menu {
  // margin-left: $header-elements-gap;
}

#main-header {
  // border-bottom: 1px solid $table-border-color;

  .et_header_style_centered & {
    .container {
      @include responsive('tablet-wide', 'max') {
        width: 100%;
        padding: 0 15px;
      }
    }

    .select_page {
      display: none;
    }

    &.et-fixed-header {
      .logo_container {
        height: 105px !important;

        @include responsive('tablet-wide', 'max') {
          height: auto !important;
        }
      }
    }

    .mobile_nav {
      @include responsive('tablet-wide', 'max') {
        background-color: transparent;
        padding: 0;

        .mobile_menu_bar {
          top: auto;
          bottom: 12px;
          z-index: 1000;
        }

        .et_mobile_menu {
          top: 20px;
          margin-left: -15px;
          width: calc(100% + 30px); 
          background-color: #e6eef3;
          text-transform: uppercase;
        }
      }
    }

    .logo_container {
      @include responsive('tablet-wide', 'max') {
        text-align: left;

        a {
          position: static;
        }
      }
    }

    #et_mobile_nav_menu {
      @include responsive('tablet-wide', 'max') {
        margin: 0;
      }
    }
  }

  @include responsive('tablet', 'max') {
    .logo_container {
      #logo {
        max-width: 150px;
      }
      a {
        position: absolute;
        top: $logo-top-mobile;
        left: 0;
      }
    }
  }

  @include responsive('tablet-wide', 'max') {
    .mobile_nav {
      .et_mobile_menu {
        margin-left: -12.5%;
        width: calc(100% + 25%);
        padding: 0;

        li {
          border-bottom: 1px solid $submenu-link-bg-color-hover;
          &:last-child {
            border-bottom: none;
          }
          ul,
          li {
            padding-left: 0;
            a {
              padding-left: 40px;
            }
          }
          a {
            padding: 16px 16px 16px 24px;
            border-bottom-color: $table-border-color;
          }
          &.current-menu-item a {
            color: $main-menu-link-active-color !important;
          }
          &.menu-item-has-children>a {
            background-color: $submenu-link-active-bg-color;
            border-bottom: 1px solid $table-border-color;
          }
        }
      }

      &.opened .mobile_menu_bar:before {
        content: "\4d";
      }
    }
  }

  @include responsive('tablet-wide') {
    .container {
      padding-bottom: 0 !important;
      width: 80%;
    }
  }
}

#top-menu {
  position: relative;

  a {
    // border-bottom: none;
  }

  & > ul {
    & > li {
      & > a {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .et_header_style_centered & {
    & > li {
      & > a {
        padding-bottom: 7px !important;
      }
    }
  }

  li {
    &.current-menu-ancestor,
    &.current-menu-item {
      & > a {
        // color: $brand-primary;
      }
    }

    &.menu-item-has-children {
      & > a {
        &:first-child {
          padding-right: 24px !important;

          &:after {
            font-weight: inherit;
            font-size: 24px;
          }
        }
      }

      li {
        a {
          &:hover {
            color: $brand-primary-lighter;
            opacity: 1;
          }
        }
      }

      &:hover {
        & > a {
          &:before {
            opacity: 1;
          }
        }
      }
    }

    &.current-page-ancestor,
    &:hover {
      & > a {
        // color: $brand-primary !important;
      }
    }
  }

  .et-fixed-header & {
    .current-page-ancestor {
      & > a {
        // color: $brand-primary !important;
      }
    }
  }

  .sub-menu {
    padding: 0 !important;
    border-width: 2px;

    li {
      line-height: 1 !important;
      padding: 0 !important;
      display: block;

      &:last-child {
        padding-bottom: 0 !important;
      }

      a {
        padding: 12px;
        line-height: 1;
        width: 100%;
        text-transform: none;

        &:hover {
          opacity: 1;
          color: $brand-primary-lighter !important;
          background-color: inherit;
        }
      }
    }
  }
}

#et-secondary-menu {
  & > ul {
    & > li {
      a {
        padding-bottom: 0;
        line-height: 23px;
        border-bottom: 1px solid transparent;

        &:hover {
          opacity: 1;
          border-color: #fff;
        }
      }
    }
  }
}

#top-header {
  .et_fullwidth_secondary_nav & {
    .container {
      padding: 6px 15px;
      width: 100% !important;

      @include responsive('tablet-wide', 'max') {
        padding: 15px;
        width: 100%;
      }
    }
  }

  .container {
    padding-top: 0;
  }
}