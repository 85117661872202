//
// The Elegant Icon Font (Divi)
// --------------------------------------------------

// Variables
$font-divi-icons:  			'ETmodules';

%pl-icon {
  font-family: $font-divi-icons !important;
}

.pl-icon-arrow-left:before {
  @extend %pl-icon;
  content: "\34";
}

.pl-icon-arrow-right:before {
  @extend %pl-icon;
  content: "\35";
}

.pl-icon-arrow-long-left:before {
  @extend %pl-icon;
  content: "\23";
}

.pl-icon-arrow-long-right:before {
  @extend %pl-icon;
  content: "\24";
}

.pl-icon-phone:before {
  @extend %pl-icon;
  content: "\e090";
}

.pl-icon-envelope:before {
  @extend %pl-icon;
  content: "\e010";
}

.pl-icon-envelope-alt:before {
  @extend %pl-icon;
  content: "\e076";
}

.pl-icon-pencil:before {
  @extend %pl-icon;
  content: "\6a";
}

.pl-icon-search:before {
  @extend %pl-icon;
  content: "\e07a";
}

.pl-icon-pin:before {
  @extend %pl-icon;
  content: "\e081";
}

.pl-icon-pin-alt:before {
  @extend %pl-icon;
  content: "\e01d";
}

.pl-icon-ul:before {
  @extend %pl-icon;
  content: "\64";
}

.pl-icon-star:before {
  @extend %pl-icon;
  content: "\e033";
}

.pl-icon-calendar:before {
  @extend %pl-icon;
  content: "\e023";
}

.pl-icon-arrow-top-right:before {
  @extend %pl-icon;
  content: "\26";
}

.pl-icon-cart:before {
  @extend %pl-icon;
  content: "\e07a";
}