//
// Modals (Bootstrap & Popup maker)
// --------------------------------------------------

html.pum-open.pum-open-overlay.pum-open-scrollable body>[aria-hidden] {
  padding-right: 0 !important;
  overflow-y: hidden !important;
}

.pum-title {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.modal .pl-btn-simple {
  display: block;
  margin: 0 auto;
}

// .modal label {
//   color: $heading-color;
//   font-weight: $heading-font-weight;
//   margin-bottom: 0;
// }

// .modal input[type="text"] {
//   width: 100%;
//   color: $input-text-color;
//   font-size: $form-text-font-size;
//   border: 1px solid $input-border-color;
//   border-radius: $border-radius-base;
//   box-shadow: inset 0px 2px 0px 0px rgba($input-border-color, 0.08);
// }

.modal input[type="text"]:focus {
  border-color: $input-border-hover-color !important;
  outline: none;
}

.modal input.error {
  border-color: $form-validation-error-border-color;
}